import React from "react";
import Layout from "../components/layout";
import { graphql } from 'gatsby';
import { StarIcon, CheckBadgeIcon } from '@heroicons/react/20/solid';
import { Link } from "gatsby";
import QuickBooks from '../images/logos/quickbooks.svg';
import Xero from '../images/logos/xero-icon.svg';
import xero from '../images/logos/xero-icon-white.svg';
import { motion } from "framer-motion"
import SEO from "../components/seo/seo-new"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export const reviewsQuery = graphql`
query MyQueryReviews ($language: String!) {
  allPrismicTestimonial {
    nodes {
      data {
        testimonial_text {
          html
        }
        seller_sales_amount
        review_location
        name
        avatar {
          url
        }
      }

    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }


}
`




export default function Reviews({ data }) {
    const { t } = useTranslation();
    const testimonials = data.allPrismicTestimonial.nodes

    const lang = data.locales.edges[0].node.language
    const description = t('Verified reviews of taxomate from Amazon, Shopify, eBay, Walmart Sellers. Read reviews from our customers.')
    const title = t('Verified reviews from taxomate customers')


    return (
        <Layout>
            <SEO
                description={description}
                title={title}
                image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
                pathname='/reviews/'
                originalPathname='/reviews/'
                lang={lang}
                alternate
            />


            <div className="py-20">
                <h1 className="text-3xl py-5 font-bold text-gray-700 sm:text-5xl text-center">
                <Trans>Ecommerce Sellers </Trans> <span className="text-blue-600"><Trans>Love Us</Trans></span>
                </h1>
                <div className="justify justify-center text-center">
                    <div className="inline-flex items-center divide-x divide-gray-300">
                        <div className="shrink-0 flex pr-5">
                            <StarIcon className="h-10 w-10 text-yellow-400" aria-hidden="true" />
                            <StarIcon className="h-10 w-10 text-yellow-400" aria-hidden="true" />
                            <StarIcon className="h-10 w-10 text-yellow-400" aria-hidden="true" />
                            <StarIcon className="h-10 w-10 text-yellow-400" aria-hidden="true" />
                            <StarIcon className="h-10 w-10 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                            <span className="font-medium text-gray-900"><Trans>5 Star Reviews</Trans></span><Trans> from Hundreds of Sellers </Trans></div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:px-20 py-30 gap-6">

                {testimonials.map((testimonial) => (

                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ duration: 1 }}
                        variants={{
                            visible: { opacity: 1, scale: 1 },
                            hidden: { opacity: 0, scale: 0 }
                        }}
                    >

                    <div className="pt-5">
                        <div className="group w-full bg-white rounded-lg relative flex flex-col items-center hover:bg-blue-600 cursor-pointer shadow md:p-10 p-6">

                            {/* <img className="absolute top-0 right-0 h-16 w-16" src={testimonial.data.review_location === "QuickBooks" ? QuickBooks : Xero} alt={`${testimonial.data.name} review on taxomate`} /> */}

                            <div className="text-gray-600 group-hover:text-white flex flex-col items-center">
                                <div className="hrink-0 flex pr-5">
                                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                </div>
                                {/* <svg width={26} height={27} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0)">
                                        <path d="M25.2578 14.3309H19.2969C19.3988 9.55819 20.6309 9.01642 22.1785 8.86178L22.7753 8.78051V3.53242L22.0874 3.57292C20.0666 3.69783 17.8323 4.09805 16.3417 6.11965C15.035 7.89183 14.459 10.7871 14.459 15.2316V23.4673H25.2578V14.3309Z" fill="currentColor" />
                                        <path d="M11.48 23.4673V14.3309H5.59859C5.70049 9.55819 6.89283 9.01642 8.44042 8.86178L8.99749 8.78051V3.53242L8.34931 3.57292C6.32844 3.69783 4.07421 4.09805 2.5836 6.11965C1.27707 7.89183 0.681147 10.7871 0.681147 15.2316V23.4673H11.48Z" fill="currentColor" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="24.5767" height={27} fill="white" transform="translate(25.2578 27) rotate(-180)" />
                                        </clipPath>
                                    </defs>
                                </svg> */}
                                <p className="xl:w-80 text-sm leading-normal text-center mt-4"><div dangerouslySetInnerHTML={{ __html: testimonial.data.testimonial_text.html }} /></p>
                                <div className="inline-flex items-center text-sm font-bold text-blue-600 group-hover:text-white">
                                    <CheckBadgeIcon className="h-9 w-9 px-2" />
                                    {testimonial.data.review_location === "QuickBooks" ? `Verified — QuickBooks App Store` : ``}
                                    {testimonial.data.review_location === "Xero" ? `Verified — Xero App Store` : ``}
                                </div>
                            </div>
                            <div className="text-white group-hover:text-blue-600 absolute bottom-0 -mb-6">
                                <svg width={34} height={28} viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_dd)">
                                        <path d="M17 19L28.2583 3.25H5.74167L17 19Z" fill="currentColor" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_dd" x="0.741699" y="0.25" width="32.5167" height="27.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                            <feMorphology radius={1} operator="erode" in="SourceAlpha" result="effect1_dropShadow" />
                                            <feOffset dy={4} />
                                            <feGaussianBlur stdDeviation={3} />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                            <feOffset dy={2} />
                                            <feGaussianBlur stdDeviation="2.5" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                            <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center mt-5">
                            <img src={testimonial.data.avatar.url} alt="profile pictre" className="w-12 h-12 rounded-full" />
                            <p className="text-base font-semibold leading-4 my-2 text-gray-800">{testimonial.data.name}</p>
                            <p className="text-base leading-4 text-center text-gray-600">{testimonial.data.seller_sales_amount}</p>
                        </div>

                    </div>
                    </motion.div>

                ))}


            </div>

            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1 }}
                variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 }
                }}
            >
            <section className="flex flex-wrap justify-center gap-5 h-full py-20 ">
                    <Link target="_blank" className='transform hover:scale-105 bg-quickbooks inline w-full md:w-1/3 rounded-lg shadow-xl' to="https://quickbooks.intuit.com/app/apps/appdetails/amazonintegration">
                    <div className="flex text-white font-bold p-6 text-center">
                        <img className="h-16" src={QuickBooks} alt="top amazon integration reviews on quickbooks" />

                            <p className="lg:pt-4"> <Trans>See More Reviews on QuickBooks App Store</Trans> →</p>

                        </div>
                    </Link>
                    <Link target="_blank" className='transform hover:scale-105 bg-xero w-full md:w-1/3 rounded-lg shadow-xl' to="https://apps.xero.com/us/app/taxomate">
                    <div className="flex text-white font-bold p-6 text-center">
                            <img className="h-16" src={xero} alt="top amazon integration reviews on xero" />
                            <p className="lg:pt-4">  <Trans>See More Reviews on Xero App Store</Trans> →</p>

                    </div>

                    </Link>

            </section>
            </motion.div>




        </Layout>
    );

}
